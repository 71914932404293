import { Inject, Injectable } from '@angular/core';
import { CONFIG } from '../config';
import { RequestService } from './request.service';
import { PreferencesService } from './preferences.service';
import {
  getPublicSystemOptions,
  loadNamespaces,
  loadPublicSystemOptions,
  setUpdateStatus
} from '../store/system-options';
import { Store } from '@ngrx/store';
import { filter } from '../rxjs-common';
import { loadLanguage } from '@translations/json-language-loader';
import { getCurrUser } from '../store/curr-user';
import { PublicSystemOptions } from '../interfaces/entity-interfaces';

@Injectable({ providedIn: 'root' })
export class AppInitService {
    translations: { [key: string]: any } = {};
    publicSysOptions: PublicSystemOptions;
    currentLang: string;

    constructor(
        @Inject(Store) private store: Store,
        @Inject(RequestService) private Request: RequestService,
        @Inject(PreferencesService) private Prefs: PreferencesService,
    ) {
      this.store.select(getPublicSystemOptions).pipe(filter((val) => !!val)).subscribe((value) => this.publicSysOptions = value);
    }

    private getParameterByName(name: string) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');

        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        let results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

  loadTranslations(lang: string): Promise<any> {
    const promises = [loadLanguage(lang)];

    if (this.publicSysOptions.namespace && !this.publicSysOptions.namespace?.includes('master')) promises.push(this.Request.get(`${CONFIG.api}public/custom_translation/${lang}`));

    console.log('Promises array length: ', promises.length);
    return Promise.all(promises).then((response) => {
      console.log('Response array: ', response);
      if (promises.length > 1 && response.length > 1) Object.assign(response[0], ...Object.entries(response[1]).map(([key, value]) => ({ [key]: value })));
      this.translations[lang] = response[0];
      this.currentLang = lang;

      return response;
    });
  }

    getPublicOptions(): Promise<void> {
      let namespacesInited: boolean;
        return new Promise((resolve) => {
            this.store.dispatch(loadPublicSystemOptions());
            this.store.select(getPublicSystemOptions)
               .pipe(filter((publicOptions) => Object.keys(publicOptions).length > 0))
               .subscribe(async (publicSystemOptions) => {
                   await this.loadTranslations(this.Prefs.language() || publicSystemOptions.defaultLanguage);
                   if (publicSystemOptions.corporate || publicSystemOptions.corporateChild) {
                     this.store.select(getCurrUser).subscribe((user) => {
                       if (user && !namespacesInited) {
                         namespacesInited = true;
                         this.store.dispatch(loadNamespaces());
                       }
                     });
                   }
                   resolve();
               });

        });
    }

    initialize() {
        const triggerUpdate = () => {
            this.store.dispatch(setUpdateStatus({ payload: true }));
        };

        return this.Request.get(`${CONFIG.root}ping`)
            .then((response: any) => {
                return response.deploy ?
                    triggerUpdate() :
                    this.getPublicOptions();

            })
            .catch(() => triggerUpdate());

    }

}
